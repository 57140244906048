/* global google */
import { React, useEffect } from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {
    GoogleMap,
    InfoWindow,
    Marker,
    useLoadScript,
} from "@react-google-maps/api";
import { useState } from "react";
import "./map.css";

const containerStyle = {
    width: '100px',
    height: '100%',
    frameBorder: 0
};




function ListingMap() {

    console.log('started');

    var options = {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0,
    };
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyD93LhEQb73HOxNelFa5YtVXu8BjwTi9ZY',
    });
    const [mapRef, setMapRef] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();


    var [latitude, setLatitude] = useState(null);
    var [longitude, setLongitude] = useState(null);
    const [markers, setMarker] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setLatitude(latitude);
                    setLongitude(longitude);
                    console.log("jjjjjjjjjjj1", latitude, longitude);
                    setMarker([
                        { address: "Address1", lat: latitude, lng: longitude },]);
                    console.log("jjjjjjjjjjj2", latitude, longitude);
                },
                error => {
                    console.error('Error getting location:', error.message);
                }, {
                enableHighAccuracy: true,
                timeout: 55000,
                maximumAge: 0,
            }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }

    }, []);




    const onMapLoad = (map) => {
        console.log('pp', map);
        if (map) {
            console.log('oo', map);
            setMapRef(map);
            const bounds = new window.google.maps.LatLngBounds();
            markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
            map.fitBounds(bounds);
        }
    };

    const handleMarkerClick = (id, lat, lng, address) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, address });
        setIsOpen(true);
    };

    return (
        <div className="App">
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (


                <div className="map-container">
                    <GoogleMap
                        mapContainerClassName="map-container"
                        onLoad={onMapLoad}
                        onClick={() => setIsOpen(false)}
                    >
                {markers && markers.map(({ address, lat, lng }, ind) => (
                            <Marker
                                key={ind}
                                position={{ lat, lng }}
                                onClick={() => {
                                    // handleMarkerClick(ind, lat, lng, address);
                                }}
                            >
                                {isOpen && infoWindowData?.id === ind && (
                                    <InfoWindow
                                        onCloseClick={() => {
                                            setIsOpen(false);
                                        }}
                                    >
                                        <h3>{infoWindowData.address}</h3>
                                    </InfoWindow>
                                )}
                            </Marker>
                        ))}
                    </GoogleMap>
                    <div className="col-md-6">
                        <div className="input-item input-item-name ltn__custom-icon">
                            <input type="text" name="latitude" placeholder="Latitude (for Google Maps)" value={latitude}
                                onChange={(e) => {
                                    setLatitude(e.target.value)
                                    console.log('onChange select ', e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input-item input-item-name ltn__custom-icon">
                            <input type="text" name="longitude" placeholder="Longitude (for Google Maps)" value={longitude}
                                onChange={(e) => {
                                    setLongitude(e.target.value)
                                    console.log('onChange select ', e)
                                }}

                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ListingMap;